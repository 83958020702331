<template>
  <div class="bgd-img-ma " :style="{'background-image':`url(${require('@/assets/images/pages/LoginBG.png')})`,'backgroundRepeat': 'no-repeat'}">
    <div class="vx-row items-center w-full h-screen no-gutter flex justify-center">
      <div
        class="vx-col sm:w-4/5 md:w-4/5 lg:w-3/4 xl:w-4/5 sm:m-0 items-center w-full no-gutter justify-center"
      >
        <div class="vx-row no-gutter justify-center pb-6">
          <p
            style="
              text-align: center;
              font: normal normal 50 18px/30px UbuntuRegular;
              letter-spacing: 0px;
              color: #ffffff;
              opacity: 0.7;
            "
          >
            {{ $t("Welcome") }}
          </p>
        </div>
        <div class="vx-row no-gutter justify-center pb-5 pt-4">
          <img
            src="@/assets/images/logo.png"
            style="max-width: 200px"
            alt="login"
          />
        </div>
        <div class="vx-row no-gutter justify-center pb-6">
          <p
            style="
              font: normal normal 100 33px/55px UbuntuRegular;
              letter-spacing: 0px;
              color: #ffffff;
              opacity: 1;
            "
          >
            {{ $t("signinasa") }}
          </p>
        </div>
        <!-- "checked == 'Hospital' ? Color : 'white'" -->
        <!-- "border" -->
        <div class="vx-row no-gutter items-center justify-center pt-3">
          <div class="vx-col p-3">
            <CustomButton
              :Color="checked == 'Hospital' ? '#00c226' : 'white'"
              title="Hospital"
              Type="border"
              @click="
                checkUser('Hospital');
                ShowSocialLogin = false;
              "
            />
          </div>
          <div class="vx-col p-3">
            <CustomButton
              :Color="checked == 'Doctor' ? '#00c226' : 'white'"
              title="Doctor"
              Type="border"
              @click="
                checkUser('Doctor');
                ShowSocialLogin = false;
              "
            />
          </div>
          <div class="vx-col p-3">
            <CustomButton
              :Color="checked == 'Patient' ? '#00c226' : 'white'"
              title="Patient"
              Type="border"
              @click="
                checkUser('Patient');
                ShowSocialLogin = true;
              "
            />
          </div>

        </div>
      </div>
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-2/5 sm:m-0 m-3">
        <vx-card>
          <div slot="no-body">
            <div
              class="vx-row no-gutter justify-center items-center"
              style="
                background: transparent
                  radial-gradient(closest-side at 50% 50%, #5dbeff 0%, #2f5f80 100%) 0% 0%
                  no-repeat padding-box;

                opacity: 1;
              "
            >
              <div class="vx-col md:w-full lg:w-2/3 d-theme-dark-bg">
                <div class="px-4 login-tabs-container">
                  <div class="vx-card__title mb-4 mt-5">
                    <h4 class="mb-4">{{ $t("Login") }}</h4>
                    <p>{{ $t("WelcomeBack") }}</p>
                  </div>
                  <login-jwt class="mb-10" :ShowSocialLogin="ShowSocialLogin"></login-jwt>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import LoginJwt from "./LoginJWT.vue";
import CustomButton from "@/components/CustomButton.vue";
import facebookLogin from 'facebook-login-vuejs';


export default {
  data() {
    return {
      checked: "Patient",
      ShowSocialLogin: true,

    };
  },
  components: {
    LoginJwt,
    CustomButton,
    facebookLogin
    //LoginFirebase,
    //LoginAuth0
  },
  methods: {
    checkUser(type) {
      debugger;
      this.checked = type;
      this.$store.commit("auth/CHANGE_ROLENAME", type);
      debugger;
    },
  },
  created() {
    this.checked = "Patient";
    this.ShowSocialLogin = true;
    this.$store.commit("auth/CHANGE_ROLENAME", this.checked);
    debugger;
  },
};
</script>
<style lang="scss">
.bgd-img-ma {

    // background-image: url("../../../assets/images/pages/LoginBG.png");
    background-size: 100% 60%;
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
  }
  </style>
