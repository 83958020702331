<template>
  <div id="loginPage">
    <div id="fb-root"></div>
    <vs-input
      data-vv-validate-on="blur"
      name="email"
      icon-no-border
      icon="icon icon-user"
      icon-pack="feather"
      label-placeholder="Email"
      :placeholder="$t('UserName')"
      v-model="email"
      :icon-after="true"
      class="w-full"
    />

    <span class="text-danger text-sm">{{ errors.first("email") }}</span>

    <vs-input
      data-vv-validate-on="blur"
      v-validate="'required|min:6|max:40'"
      id="Password"
      name="password"
      icon-no-border
      icon-pack="feather"
      label-placeholder="Password"
      :placeholder="$t('Password')"
      :icon-after="true"
      :icon="PasswordIcon"
      @icon-click="TextToPassword()"
      v-model="password"
      type="password"
      class="w-full mt-6"
    />

    <span class="text-danger text-sm">{{ errors.first("password") }}</span>

    <div id="checkFont" class="flex flex-wrap justify-between my-5">
      <vs-checkbox v-model="checkbox_remember_me" class="text-sm mb-3">{{
        $t("RememberMe")
      }}</vs-checkbox>
      <router-link to="/pages/forgot-password" class="text-sm">{{
        $t("ForgotPassword")
      }}</router-link>
    </div>

    <div class="flex flex-wrap justify-between mb-3">
      <vs-button type="border" @click="gotoSignup">{{
        $t("Signup")
      }}</vs-button>
      <vs-button :disabled="!validateForm" @click="loginJWT">{{
        $t("Login")
      }}</vs-button>
    </div>
    <div class="flex flex-wrap justify-center mb-3">
      <router-link to="/" class="text-sm">{{
        $t("Continueasguest")
      }}</router-link>
    </div>

    <div v-if="ShowSocialLogin"></div>
    <div class="flex flex-wrap justify-center mb-3" v-if="ShowSocialLogin">

        <template>
        <g-signin-button
          :params="googleSignInParams"
          @success="onSignInSuccess"
          @error="onSignInError"

        >
          Sign in with Google
        </g-signin-button>

      </template> 

    </div>
    <!-- <div class="col-md-12">
              <div class="login-or">
                <div class="text-center">
                  <span class="span-or">- {{ $t("or") }} -</span>
                </div>
              </div>
            </div> -->

             <!-- <div class="col-md-12 mb-3">
              <div class="col-md-8 mx-auto mb-3">
                <fb-signin-button class="mb-1" />
                 \<g-signin-button   @done="onUserLoggedIn" />  
              </div>
            </div> -->

    <div class="flex flex-wrap justify-center mb-3" v-if="ShowSocialLogin">


      <template >

        <facebook-login
        id="myface"
          style="padding: 0.5rem 3.6rem; width: auto"
          appId="854411072246147"
          clientsecrect="324cf5cac4839903fb40909239b25d62"
          @login="getUserData"
          @logout="onLogout"
          @get-initial-status="getUserData"
        >
        </facebook-login>
      </template> 
      

      <!-- <template>
        <div  id="FbSigninBtn" ref="FbSigninBtn" class="fb-login-button"  data-width="2000"  data-size="large"
         data-button-type="login_with" data-layout="rounded" data-auto-logout-link="false" 
         data-use-continue-as="true" data-scope="email" >
        </div>
      </template> -->

      <!-- <template>
  <div class="signup-buttons">
    <div id="fb-root"></div>
    <a href="#" class="facebook-signup" @click.prevent="loginWithFacebook">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="#3578E5"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
      Facebook
    </a>
  </div>
</template> -->
    </div>

    
    <div class="flex flex-wrap justify-center mb-3" v-if="ShowSocialLogin">
     
      <!-- <g-signin-button @done="onUserLoggedIn" /> -->

      <!-- <template>
        <div ref="signinBtn">
          <a style="color:#4285F4" class="mb-3 border shadow rounded-pill  btn btn-white btn-block mybtn tx-tfm">
             <i  class="fa fa-google fa-fw" @done="onUserLoggedIn">
        </i> Login with Google</a >
      </div>
      </template> -->
      <template>
    <!-- <div class="signup-buttons">
        <div id="fb-root"></div>
        <a href="#" class="google-signup" @click.prevent="loginWithGoogle">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" aria-hidden="true"><title>Google</title><g fill="none" fill-rule="evenodd"><path fill="#4285F4" d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"></path><path fill="#34A853" d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"></path><path fill="#FBBC05" d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"></path><path fill="#EA4335" d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"></path></g></svg>
        Sign in with Google
        </a>  
        <br />
        <a href="#" class="facebook-signup" @click.prevent="loginWithFacebook">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="#3578E5"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
          Sign in with Facebook
        </a>
    </div> -->
</template>

    </div>
    <!-- <fb:login-button 
  scope="public_profile,email"
  onlogin="checkLoginState();">
</fb:login-button> -->

    <!-- <div class="fb-login-button" data-width="" data-size="large" data-button-type="continue_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false"></div> -->
    <!-- <div id="status"> -->
<!-- </div> -->
<!-- <v-facebook-login app-id="854411072246147"  @login="logInWithFacebook" ></v-facebook-login> -->
<!-- <button class="button"  @click="logInWithFacebook" scope="public_profile,email"> Login with Facebook</button> -->


</div>
</template>
<!-- <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v15.0&appId=854411072246147&autoLogAppEvents=1" nonce="wdmSsbPl"></script> -->

<script>
import GoogleSignInButton from "vue-google-signin-button-directive";
import Vue from "vue";
import GSignInButton from "vue-google-signin-button";
import facebookLogin from "facebook-login-vuejs";
import { initFbsdk } from '@/config/fb.js'
import { VFBLogin } from 'facebook-login-vuejs'
import GoogleAuth from '@/config/google_oAuth.js'

const gauthOption = {
  clientId: '140759690297-8266gprptftfmb0q9tka9b75dphuc24b.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GoogleAuth, gauthOption)
// Vue.component("g-signin-button", {
//   template:
//     '<div ref="signinBtn"><a style="color:#4285F4" class="mb-3 border shadow rounded-pill  btn btn-white btn-block mybtn tx-tfm"> <i  class="fa fa-google fa-fw"></i> Login with Google</a ></div>',
//   mounted() {
//     window.gapi.load("auth2", () => {
//       const auth2 = window.gapi.auth2.init({
//         client_id:
//           //"334917801561-lcuv39tkltngn4bbq7dkj9e40b1pbkil.apps.googleusercontent.com",
//           "140759690297-8266gprptftfmb0q9tka9b75dphuc24b.apps.googleusercontent.com",
//         cookiepolicy: "single_host_origin",
//       });
//       auth2.attachClickHandler(
//         this.$refs.signinBtn,
//         {},
//         (googleUser) => {
//           this.$emit("done", googleUser);
//         },
//         (error) => console.log(error)
//       );
//     });
//   },
// });

/////////////////////////new Social Login ////////////////////
// Vue.component("fb-signin-button", {
//   template:
//     '<div  id="FbSigninBtn" ref="FbSigninBtn" class="fb-login-button"  data-width="2000"  data-size="large" data-button-type="login_with" data-layout="rounded" data-auto-logout-link="false" data-use-continue-as="true" data-scope="email" ></div>',
//   mounted() {
//     debugger;
//     window.fbAsyncInit = () => {
//       FB.init({
//         //developmentID
//         appId: "1306323336841563",
//        //LiveAPPID
//       //  appId:"549738960258999",
//         cookie: true,
//         status: true,
//         xfbml: true,
//         version: "v10.0",
//       });

//       FB.Event.subscribe("auth.login", (response) => {
//         /* make the API call */
//         console.log(response);
//         FB.api(
//           "/me",
//           {
//             fields: "name, email",
//           },
//           function (response) {
//             console.log(response.email);
//             console.log(response.name);
//             var Model = {
//               name: response.name,
//               email: response.email,
//               provider: "facebook",
//               //developmentID
//               provider_Id: "1306323336841563",
//               //liveproducationid
//               //provider_Id:"549738960258999",
//               fireBaseToken: "",
//               profilePic: "",
//             };
//             debugger;
//             window.store.dispatch("auth/SocialLogin", Model);
//             console.log( window.store.dispatch("auth/SocialLogin", Model));
//             this.$store.dispatch("auth/SocialLogin", Model)
//         .then((response) => {
//           if (response.status == 200) {
//             debugger;
//             this.$router.push("/Patient/Home");
//           }
//         })

//         .catch(() => {});

//           }
//         );

//         // do something with response
//         // FB.login(
//         //   function(response) {
//         //     // handle the response
//         //     console.log(response);
//         //   },
//         //   { scope: "public_profile,email" }
//         // );
//       });
//       FB.getLoginStatus((response) => {
//         if (response.status === "connected") {
//           console.log(response);
//           // The user is logged in and has authenticated your
//           // app, and response.authResponse supplies
//           // the user's ID, a valid access token, a signed
//           // request, and the time the access token
//           // and signed request each expire.
//           var uid = response.authResponse.userID;
//           var accessToken = response.authResponse.accessToken;
//           FB.login(
//             function (response) {
//               // handle the response
//               console.log(response);
//             },
//             { scope: "public_profile,email" }
//           );
//           FB.api(
//             "/" + response.userID + "/?fields=id,name,email",
//             "GET",
//             {},
//             function (response) {
//               // Insert your code here
//               // console.log(response);
//               let email = response.email;
//               console.log(email);
//             }
//           );

//           FB.logout(function (response) {
//             console.log(response);
//             // user is now logged out
//           });
//         } else if (response.status === "not_authorized") {
//           // The user hasn't authorized your application.  They
//           // must click the Login button, or you must call FB.login
//           // in response to a user gesture, to launch a login dialog.
//         } else {
//           // The user isn't logged in to Facebook. You can launch a
//           // login dialog with a user gesture, but the user may have
//           // to log in to Facebook before authorizing your application.
//         }
//       });
//       FB.login((response) => {
//         console.log(response);
//       });
//       FB.AppEvents.logPageView();

//       FB.getAuthResponse((response) => {
//         debugger;
//         // body...
//         FB.api(
//           "/" + response.userID + "/?fields=id,name,email",
//           "GET",
//           {},
//           function (response) {
//             // Insert your code here
//             // console.log(response);
//             let email = response.email;
//             console.log(email);
//           }
//         );
//         console.log(response);
//         debugger;
//         statusChangeCallback(response);
//       });
//     };
//     (function (document, s, id) {
//       var js,
//         fjs = document.getElementsByTagName(s)[0];
//       if (document.getElementById(id)) {
//         return;
//       }
//       js = document.createElement(s);
//       js.id = id;
//       js.src = "https://connect.facebook.net/en_US/sdk.js";
//       fjs.parentNode.insertBefore(js, fjs);
//     })(document, "script", "facebook-jssdk");
//     // debugger;
//     var js,
//       id = "facebook-jssdk";
//     if (document.getElementById(id)) {
//       return;
//     }
//     js = document.createElement("script");
//     js.id = id;
//     js.async = true;
//     js.src = "//connect.facebook.net/en_US/all.js";
//     document.getElementsByTagName("head")[0].appendChild(js);

//     window.fbAsyncInit("init", () => {
//       const FB = window.FB.init({
//         appId: "1306323336841563",
//         cookie: true,
//         xfbml: true,
//         version: "v10.0",
//         autoLogAppEvents: true,
//       });
//       FB.attachClickHandler(
//         this.$refs.FbSigninBtn,
//         {},
//         (googleUser) => {
//           this.$emit("done", googleUser);
//         },
//         (error) => console.log(error)
//       );

//       FB.getAuthResponse(function (response) {
//         debugger;
//         console.log(response);
//         debugger;
//         statusChangeCallback(response);
//       });
//     });
//   },
// });

// Vue.component("g-signin-button", {
//   template:
//     '<div ref="signinBtn"><a style="color:#4285F4" class="mb-3 border shadow rounded-pill  btn btn-white btn-block mybtn tx-tfm"> <i  class="fa fa-google fa-fw"></i> Login with Google</a ></div>',
//   mounted() {
//     window.gapi.load("auth2", () => {
//       const auth2 = window.gapi.auth2.init({
//         client_id:
//           "140759690297-8266gprptftfmb0q9tka9b75dphuc24b.apps.googleusercontent.com",
//         cookiepolicy: "single_host_origin",
//       });
//       auth2.attachClickHandler(
//         this.$refs.signinBtn,
//         {},
//         (googleUser) => {
//           this.$emit("done", googleUser);
//         },
//         (error) => console.log(error)
//       );
//     });
//   },
// });


////////////////////////////////////////////////////////////

// Vue.component("fb-signin", {
//   components: {
//     facebookLogin,
//     VFBLogin
//   },
// });

 Vue.use(GSignInButton);
export default {
  name: 'login_signup_social',
  directives: {
    GoogleSignInButton,
  },
  data() {
    return {
      clientId:
        "355943443816-c66if3g8gmg30kd4dfsltsvopk4queo7.apps.googleusercontent.com",
      email: "",
      password: "",
      name: "",
      FB: undefined,
      personalID: "",
      isconnected: false,
      checkbox_remember_me: false,
      PasswordIcon: "icon icon-eye-off",
      googleSignInParams: {
        client_id:
          "355943443816-c66if3g8gmg30kd4dfsltsvopk4queo7.apps.googleusercontent.com",
      // client_secret:"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"
      scope:'email',
      plugin_name:'DocliniaTest'
      },
    };
  },
  components: {
    facebookLogin,
  },
  props: {
    ShowSocialLogin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    },
    UserType() {
      debugger;
      return this.$store.state.auth.UserType;
    },
  },
  mounted () {
    debugger;
    var element = document.getElementById("myface");
  element.classList.remove("container");
    // initFbsdk()
  },
  methods: {
//     async logInWithFacebook() {
//       debugger
//       await this.loadFacebookSDK(document, "script", "facebook-jssdk");
//       await this.initFacebook();
//       window.FB.login(function(response) {
//         if (response.authResponse) {
//           window.FB.api(
//   '/me',
//   'GET',
//   {"fields":"id,name,email"},
//   function(myresponse) {
//     debugger
//       // Insert your code here
//       console.log(myresponse)
//   }
// );
//           debugger
//           fetch('https://graph.facebook.com/'+ response.authResponse.userID +'?fields=id,name,email,picture&access_token='+response.authResponse.accesstoken).then(res=>{
//             debugger
//             console.log(res)
//           })
//           alert("You are logged in &amp; cookie set!" + response.authResponse);
//           var uid = "http://graph.facebook.com/" + response.authResponse.userID + "/picture";
 
//           window.FB.api('/v15.0/me?fields=email,name', function(me) {
//             debugger
//             alert("You name" + me.name) ;
//             alert("You email" + me.email) ;
//             alert("You uid" + uid) ;

// })
//           window.FB.api(`/v15.0/me?scope=email/${response.authResponse.userID}/?fields=id,name,email`, userResponse => {
//             if (userResponse) {
//               console.log(userResponse);
//               debugger
//               var userInfo = {
//                 loginType: 'fb',
//                 fb: {
//                   auth: response.authResponse,
//                   user: userResponse
//                 }
//               }
//               alert("You userInfo" + userInfo) ;
//       //         var Model = {
//       //    name: userInfo.getName(),
//       //    email: userInfo.getEmail(),
//       //    provider: "facebook",
//       //    provider_Id:
//       //      "549738960258999",
//       //    profilePic: userInfo.getImageUrl(),
//       //  };  
//             //  this.$store.commit('setLoginUser', userInfo)
//             }
//           });
//           // Now you can redirect the user or do an AJAX request to
//           // a PHP script that grabs the signed request from the cookie.
//         } else {
//           alert("User cancelled login or did not fully authorize.");
//         }
//       });
//       return false;
//     },
//     async initFacebook() {
//       window.fbAsyncInit = function() {
//         window.FB.init({
//           appId: '854411072246147',
//         cookie: true, // enable cookies to allow the server to access the session
//         xfbml: true, // parse social plugins on this page
//         version: 'v15.0', // use graph api version 2.8
//          scop:'email'
//         });
//       };
//     },
//     async loadFacebookSDK(d, s, id) {
//       var js,
//         fjs = d.getElementsByTagName(s)[0];
//       if (d.getElementById(id)) {
//         return;
//       }
//       js = d.createElement(s);
//       js.id = id;
//       js.src = "https://connect.facebook.net/en_US/sdk.js";
//       fjs.parentNode.insertBefore(js, fjs);
//     },
faceboocSocialLogin(model){
  debugger
  this.$store.
            dispatch("auth/SocialLogin", model)
        .then((response) => {
          if (response.status == 200) {
            debugger;
            this.$acl.change(response.data.Data.userData.userRole)
            if (
              response.data.Data.userData.Patient.CompletedRegisterationData != true
            )
              this.$router.push({
                name: "CompletedRegisterationData",
                params: { ID: response.data.Data.userData.Patient.ID },
              });
              else
               this.$router.push("/Patient/Home")
          }
        })
},
    getUserData(data){
debugger
var model = {};
var vm = this;
window.FB.api('/v15.0/me?fields=email,name', function(me) {
            debugger
            // alert("You name" + me.name) ;
            // alert("You email" + me.email) ;

            // alert("You uid" + uid) ;
           
            model.Provider_Id = me.id;
            model.Email = me.email;
            model.Provider = "Facebook";
            vm.faceboocSocialLogin(model)
           

})

    },
//////////////////////////////////social with diffrent way ///////
// statusChangeCallback(response) {  // Called with the results from FB.getLoginStatus().
//   debugger  
//   console.log('statusChangeCallback');
//     console.log(response);                   // The current login status of the person.
//     if (response.status === 'connected') {   // Logged into your webpage and Facebook.
//       testAPI();  
//     } else {                                 // Not logged into your webpage or we are unable to tell.
//       document.getElementById('status').innerHTML = 'Please log ' +
//         'into this webpage.';
//     }
//   },


//    checkLoginState() { 
//     debugger              // Called when a person is finished with the Login Button.
//     FB.getLoginStatus(function(response) {   // See the onlogin handler
//       statusChangeCallback(response);
//     });
//   },
//   testAPI() {                      // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
//    debugger
//     console.log('Welcome!  Fetching your information.... ');
//     FB.api('/me', function(response) {
//       console.log('Successful login for: ' + response.name);
//       document.getElementById('status').innerHTML =
//         'Thanks for logging in, ' + response.name + '!';
//     });
//   },
loginWithGoogle () {
  debugger;
      this.$gAuth
        .signIn()
        .then(GoogleUser => {
          // on success do something
          console.log('GoogleUser', GoogleUser)
          console.log('getId', GoogleUser.getId())
          console.log('basicprofile', GoogleUser.getBasicProfile().getName())
          console.log('getBasicProfile', GoogleUser.getBasicProfile())
          console.log('getAuthResponse', GoogleUser.getAuthResponse())
          var userInfo = {
            loginType: 'google',
            google: {
              auth: GoogleUser.getAuthResponse(),
              user: {
                name: GoogleUser.getBasicProfile().getName(),
                email: GoogleUser.getBasicProfile().getEmail(),
                profileImage: GoogleUser.getBasicProfile().getImageUrl()
              }
            }
          };
      var Model = {
        name: profile.getName(),
        email: profile.getEmail(),
        provider: "google",
        provider_Id:
          "1097401738041-sslg7qpmrimqv1d6jjda7l6bdeli7420.apps.googleusercontent.com",
        fireBaseToken: "",
        profilePic: profile.getImageUrl(),
      };
      this.$store
        .dispatch("auth/SocialLogin", Model)
        .then((response) => {
          if (response.status == 200) {
            debugger;
            this.$router.push("/Patient/Home");
          }
        })

        .catch(() => {});
         // this.$store.commit('setLoginUser', userInfo)
         // router.push('/home')
        })
        .catch(error => {
          console.log('error', error)
        })
    },

    // loginWithFacebook () {
    //   debugger;
    //   window.FB.login(response => {
    //     if (response && response.authResponse) {
    //       console.log('response', response)
    //       var userInfo = {
    //         loginType: 'fb',
    //         fb: {
    //           auth: response.authResponse
    //         }
    //       }
    //      // this.$store.commit('setLoginUser', userInfo)
    //       window.FB.api(`/${response.authResponse.userID}`, userResponse => {
    //         if (userResponse) {
    //           console.log(userResponse);
    //           var userInfo = {
    //             loginType: 'fb',
    //             fb: {
    //               auth: response.authResponse,
    //               user: userResponse
    //             }
    //           }
    //         //  this.$store.commit('setLoginUser', userInfo)
    //         }
    //       }, this.params);
    //     //  router.push('/home')
    //     var Model = {
    //      name: userInfo.getName(),
    //      email: userInfo.getEmail(),
    //      provider: "facebook",
    //      provider_Id:
    //        "549738960258999",
    //      fireBaseToken: "",
    //      profilePic: userInfo.getImageUrl(),
    //    };        
    //     this.$store.dispatch("auth/SocialLogin", Model)
    //     .then((response) => {
    //       if (response.status == 200) {
    //         debugger;
    //         this.$router.push("/Patient/Home");
    //       }
    //     })

    //     .catch(() => {});
    //     }
    //   }, this.params)
    // },


//////////////////////////

/////////////////////new methods for social login //////////////////////////

    FbSigninBtn() {
      alert("S");
      debugger;
      window.FB.login((response) => {
        console.log(response);
        if (response.authResponse) {
          console.log("Welcome!  Fetching your information.... ");
          FB.api("/me", function (response) {
            console.log("Good to see you, " + response.name + ".");
          });
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      });
    },

    onUserLoggedIn(googleUser) {
      debugger;
      console.log(googleUser);
      var profile = googleUser.getBasicProfile();
      console.log("ID: " + profile.getId()); // Do not send to your backend! Use an ID token instead.
      console.log("Name: " + profile.getName());
      console.log("Image URL: " + profile.getImageUrl());
      console.log("Email: " + profile.getEmail()); // This is null if the 'email' scope is not present.
      var Model = {
        name: profile.getName(),
        email: profile.getEmail(),
        provider: "google",
        provider_Id:
          "355943443816-c66if3g8gmg30kd4dfsltsvopk4queo7.apps.googleusercontent.com",
        fireBaseToken: "",
        profilePic: profile.getImageUrl(),
      };
      console.log(googleUser);
      // this.$store
      //   .dispatch("auth/SocialLogin", Model)
      //   .then((response) => {
      //     if (response.status == 200) {
      //       debugger;
      //       this.$router.push("/Patient/Home");
      //     }
      //   })

      //   .catch(() => {});
      // console.log(googleUser);
    },


//     //////////////////////////////////////////////////
//     onSignInSuccessFb()
//     {
//  this.FB.api('/me','GET', {fields: 'id,name,email'},
//  UserInformation => {
//   console.warn("data api", UserInformation)
//   this.personalID = UserInformation.id;
//   this.name= UserInformation.name;
//   This.email =UserInformation.email;
//  }
//  )
//     },

//     sdkLoaded (payload){
//    this.isconnected = payload.isconnected
//    this.FB =payload.FB
//    if (this.isconnected)
//    {
//     this.getUserData()
//    }
//     },
//     Onlogin (){
//    this.isconnected =true
//    this.getUserData()
//     },

//     OnLogout(){
//       this.isconnected =false
//     },
//     async onSignInSuccessFb(getUserData) {
//       debugger;
//       var profile = getUserData();
//       // var profile = googleUser.getBasicProfile();
//       var Model = {
//          name: profile.getName(),
//          email: profile.getEmail(),
//          provider: "facebook",
//          provider_Id:
//            "549738960258999",
//          fireBaseToken: "",
//          profilePic: profile.getImageUrl(),
//        };
//        this.$store
//          .dispatch("auth/SocialLogin", Model)
//          .then((response) => {
//            if (response.status == 200) {
//       //       debugger;
//              this.$vs.notify({
//                title: this.$t("LoginAttempt"),
//                text: this.$t("You are logged in"),
//                iconPack: "feather",
//                icon: "icon-alert-circle",
//                color: "success",
//              });
//              this.$acl.change(response.data.Data.userData.userRole);
//              debugger;
//              this.$router.push("/Patient/Home");
//            }
//         })

//         .catch(() => {});
//     },

    OnGoogleAuthSuccess(googleUser) {
      debugger;
      var profile = googleUser.getBasicProfile();
      var Model = {
        name: profile.getName(),
        email: profile.getEmail(),
        provider: "google",
        provider_Id:
          "140759690297-8266gprptftfmb0q9tka9b75dphuc24b.apps.googleusercontent.com",
        fireBaseToken: "",
        profilePic: profile.getImageUrl(),
      };
      // this.$store
      //   .dispatch("auth/SocialLogin", Model)
      //   .then((response) => {
      //     if (response.status == 200) {
      //       debugger;
      //       this.$vs.notify({
      //         title: this.$t("LoginAttempt"),
      //         text: this.$t("You are logged in"),
      //         iconPack: "feather",
      //         icon: "icon-alert-circle",
      //         color: "success",
      //       });
      //       this.$acl.change(response.data.Data.userData.userRole);
      //       this.$router.push("/Patient/Home");
      //     }
      //   })

      //   .catch(() => {});
      // // Receive the idToken and make your magic with the backend
    },
    OnGoogleAuthFail(error) {
      console.log(error);
    },

    gotoSignup() {
      debugger;
      if (this.UserType == "Hospital") this.$router.push("/pages/register/3");
      else if (this.UserType == "Doctor")
        this.$router.push("/pages/register/2");
      else if (this.UserType == "Patient")
        this.$router.push("/pages/register/1");
    },
    TextToPassword() {
      debugger;
      if (document.getElementById("Password").type == "text") {
        document.getElementById("Password").type = "password";
        this.PasswordIcon = "icon icon-eye-off";
      } else {
        document.getElementById("Password").type = "text";
        this.PasswordIcon = "icon icon-eye";
      }
    },
    
    async onSignInSuccess(googleUser) {
      debugger;
      var profile = googleUser.getBasicProfile();
        console.log("ID: " + profile.getId()); // Don't send this directly to your server!
        console.log('Full Name: ' + profile.getName());
        console.log('Given Name: ' + profile.getGivenName());
        console.log('Family Name: ' + profile.getFamilyName());
        console.log("Image URL: " + profile.getImageUrl());
        console.log("Email: " + profile.getEmail());
        // The ID token you need to pass to your backend:
        var id_token = googleUser.getAuthResponse().id_token;
        console.log("ID Token: " + id_token);

      // var profile = googleUser.getBasicProfile();
      var Model = {
         name: profile.getName(),
         email: profile.getEmail(),
         provider: "google",
         provider_Id:
         "355943443816-c66if3g8gmg30kd4dfsltsvopk4queo7.apps.googleusercontent.com",
         fireBaseToken: "",
         profilePic: profile.getImageUrl(),
       };
       this.$store
         .dispatch("auth/SocialLogin", Model)
         .then((response) => {
           if (response.status == 200) {
      //       debugger;
             this.$vs.notify({
               title: this.$t("LoginAttempt"),
               text: this.$t("You are logged in"),
               iconPack: "feather",
               icon: "icon-alert-circle",
               color: "success",
             });
             this.$acl.change(response.data.Data.userData.userRole);
             debugger;
             this.$router.push("/Patient/Home");
           }
        })

        .catch(() => {});
    },


    onSignInError(error) {
      debugger;
      // `error` contains any error occurred.
      console.log("OH NOES", error);
    },
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: this.$t("LoginAttempt"),
          text: this.$t("Youarealreadyloggedin"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }
      return true;
    },

    loginJWT() {
      this.$vs.loading();
      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password,
        },
      };
      debugger;
      this.$store
        .dispatch("auth/loginJWT", payload)
        .then((res) => {
          this.$vs.loading.close();
          debugger;
          this.$acl.change(res.data.Data.userData.userRole);
          debugger;
          if (res.data.Data.userData.userRole == "patient") {
            if (
              res.data.Data.userData.Patient.CompletedRegisterationData != true
            )
              this.$router.push({
                name: "CompletedRegisterationData",
                params: { ID: res.data.Data.userData.Patient.ID },
              });
            else this.$router.push("/Patient/Home");
          } else if (res.data.Data.userData.userRole == "doctor") {
            //  debugger
            //    if(!res.data.Data.userData.IsGeneralDoctor)
            //        this.$router.push({ name:"ReservedSurgery" });
            //    else
            this.$router.push("/doctor/Home");
          } else if (res.data.Data.userData.userRole == "hospital")
            this.$router.push("/hospital/Home");
          else if (res.data.Data.userData.userRole == "hospitalgroup")
            this.$router.push("/hospital/ReservedSurgery");
        })

        .catch((err) => {
          debugger;
          this.$vs.loading.close();
          if (err && err.response.status == 400) {
            this.$vs.notify({
              title: this.$t("Error"),
              text: err.response.data.Message,
              iconPack: "feather",
              time: 5000,
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: this.$t("Error"),
              text: "Wrong User Name or Password",
              time: 5000,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },

    // onLogin() {
    //   this.isconnected = true;
    //   this.getUserData();
    // },
    onLogout() {
      //https://www.facebook.com/x/oauth/logout?access_token=EAAMJFRR0EYMBAMhBmTw9RSBVHinahCZAHNydZBZBn3azRjnz9yF0MZBwjj7NQUErIrpZCkVpnA9trtTayD2WlfXWt4sOmtu9Xzkd6ZCpU97T7tBZCUfEUwVrbqkZAVIee3UwtRgRHLZCKZB9VccFol61ZACZALZA7GAX7Y3VQbUgpJhhWkGzxKoQa2Vg6ZASnsIuA0aUpB9TZAjSzehinZCykNAGBEn0
    },
    // FbSigninBtn() {
    //   window.FB.login((response) => {
    //     console.log(response);
    //     if (response.authResponse) {
    //       console.log("Welcome!  Fetching your information.... ");
    //       FB.api("/me", function (response) {
    //         console.log("Good to see you, " + response.name + ".");
    //       });
    //     } else {
    //       console.log("User cancelled login or did not fully authorize.");
    //     }
    //   });
    // },
  },
};

// function onSignIn(googleUser) {
//         // Useful data for your client-side scripts:
//         var profile = googleUser.getBasicProfile();
//         console.log("ID: " + profile.getId()); // Don't send this directly to your server!
//         console.log('Full Name: ' + profile.getName());
//         console.log('Given Name: ' + profile.getGivenName());
//         console.log('Family Name: ' + profile.getFamilyName());
//         console.log("Image URL: " + profile.getImageUrl());
//         console.log("Email: " + profile.getEmail());

//         // The ID token you need to pass to your backend:
//         var id_token = googleUser.getAuthResponse().id_token;
//         console.log("ID Token: " + id_token);
//         this.$router.push("/Patient/Home");

//       }

</script>
<style>
.material-icons {
  font-family: "Material Icons" !important;
}
.material-icons :lang(en) {
  font-family: unset;
}
/* .google-signin-button {
  color: white;
  background-color: red;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */

.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 0.5rem 3.6rem;

  background-color: rgb(238, 20, 20);
  color: #fff;
  box-shadow: 0 3px 0 rgb(172, 85, 85);
}

.fb-login {
  display: inline-block;
  padding: 0.5rem 3.6rem;

  background-color: #004477;
  color: #fff;
  box-shadow: 0 3px 0 rgb(43, 45, 172)
}
#FbSigninBtn {
  margin-right: 3rem !important;
  width: 35rem !important;
  margin-bottom: 1rem !important;
}

.facebook-signup, .google-signup {
    color: #031b4e;
    background: #f2f8ff;
    border: 1px solid rgba(0,105,255,.2);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    display: inline-block;
    margin-top: 0;
    width: 100%;
    padding: 15px;
    text-align: center;
    position: inherit;
}
.button{
  color:white;
  min-width: 150px;
  background-color: #000000a1;
  height: 2.5rem;
  border-radius: 2rem;
  font-weight: 400;
  font-size: 0.8rem;
}
[dir] #loginPage .container {
  display: inline-block !important;
  background-color: white;
  height: 2.5rem !important;
  
}
#loginPage .button {
  display: inline-block !important;
  background-color: white;
  height: 2.5rem !important;
  
}
</style>
